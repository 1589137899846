<template>
<!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
<div id="Afterlanding" style="width:100vw">
  <div id="f293d255">
    <div id="a0857ae3">
      <img src="../assets/img-ae5ee1a6.png" id="ae5ee1a6"/>
      <div id="a8dd914a">
        <div id="a431af60">
          您的专属产品经理
        </div>
        <div id="aad0f0d2">
          韩柳
        </div>
        <div id="d57cea7e">
          金牌产品经理
        </div>
        <div id="d2be26be">
          电子邮箱：xxxxxxxxxx
        </div>
        <div id="bd7395f4">
          电话：xxxxxxxxxx
        </div>
      </div>
      <div id="e72b3603">
        <div id="a40c4e00">
          二维码
        </div>
        <img src="../assets/img-ab904f3e.png" id="ab904f3e"/>
      </div>
    </div>
    <div id="a0839ce0">
      让您的天才构想在 数周 内变为现实
    </div>
    <el-button id="a3c8b63d" @click="clicka3c8b63d()">
      立即开始
    </el-button>
  </div>
  <div id="f00af516">
    <div id="aac6cbab">
      产品案例
    </div>
    <img src="../assets/img-fae93849.png" id="fae93849"/>
  </div>
  <div id="aa1cc42d">
    <!-- <img src="../assets/img-a176989f.png" id="a176989f" @click="clicka176989f()"/>
    <img src="../assets/img-af55dd6b.png" id="af55dd6b" @click="clickaf55dd6b()"/> -->
    <div v-for="img of articleList" :key="img.articleID">
          <img :src="img.articleImg" id="a176989f" @click="clicka176989f()"/>
        </div>
  </div>
  <div id="aed91e13">
    <img src="../assets/img-a35d495b.png" id="a35d495b" @click="clicka35d495b()"/>
    <img src="../assets/img-a9878a12.png" id="a9878a12" @click="clicka9878a12()"/>
  </div>
  <div id="a31e5a3c">
    <dal-compfloat id="cmpa31e5a3c">
    </dal-compfloat>
  </div>
  <div id="dlgfaafacbdb" v-show="vis_aafacbdb" @click.self="vis_aafacbdb=false">
    <div id="aafacbdb">
      <div id="aa56df09">
        请保持手机畅通
您的产品经理会尽快与您联系！
      </div>
      <el-button id="a77268ee" @click="clicka77268ee()">
        我知道了
      </el-button>
    </div>
  </div>
</div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
import {
DALINEWEB_ARTICLECENTER_V0X0X1_getArticleList
} from '../api/dalapi.js'
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
import dal_compfloat from '../components/dal-compfloat'
export default{
  components:{
    'dal-compfloat':dal_compfloat
  },
  data(){
    return{
    vis_aafacbdb:false,
      //在此注释下方添加自定义变量
      articleList:[]
    }
  },
  mounted(){
    this.getarticlelist()
  },
  computed:{
  },
  watch:{
  },
  methods:{
    async clicka3c8b63d(){
      this.vis_aafacbdb = true
    },
    async clicka176989f(){
      this.$router.push('article')
    },
    async clickaf55dd6b(){
      this.$router.push('article')
    },
    async clicka35d495b(){
      this.$router.push('article')
    },
    async clicka9878a12(){
      this.$router.push('article')
    },
    async clicka77268ee(){
      this.vis_aafacbdb = false
    },
    //在此注释下方添加自定义函数或事件
    async getarticlelist(){
    let dicInput = {
        pnum: 1,
        records: 6,
        ofield: "insertTime",
        sort: "",
        sort_rules:"preview",
        ArticleType: 171,
      };
      dicInput.status = "0";
      const res = await DALINEWEB_ARTICLECENTER_V0X0X1_getArticleList(dicInput);
      const articleListData = JSON.parse(JSON.stringify(res.retContent));
      this.articleList = JSON.parse(articleListData.retContent).articleList
      console.log( this.articleList);
    }
  }
}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#f293d255{
  width:1323px;
  height:845px;
  position:relative;
  margin-left:163px;
  margin-top:100px;
}
#f293d255{
  background-image:url('../assets/img-c100b9b6.png');
  background-size: cover;
}
#a0857ae3{
  width:1203px;
  position:relative;
  padding-left:60px;
  padding-top:50px;
}
#ae5ee1a6{
  width:337px;
  height:337px;
  padding-bottom:37px;
  display:inline-block;
  vertical-align: middle;
}
#ae5ee1a6{
  vertical-align:middle;
}
#a8dd914a{
  width:360px;
  position:relative;
  margin-left:60px;
  padding-bottom:1px;
  display:inline-block;
  vertical-align: middle;
}
#a431af60{
}
#a431af60{
  text-align:left;
  color:#141620;
  font-size:45px;
  font-family:PingFang SC;
}
#aad0f0d2{
  margin-top:40px;
}
#aad0f0d2{
  text-align:left;
  color:#141620;
  font-size:30px;
  font-family:PingFang SC;
}
#d57cea7e{
  margin-top:34px;
}
#d57cea7e{
  text-align:left;
  color:#141620;
  font-size:30px;
  font-family:PingFang SC;
}
#d2be26be{
  margin-top:34px;
}
#d2be26be{
  text-align:left;
  color:#141620;
  font-size:30px;
  font-family:PingFang SC;
}
#bd7395f4{
  margin-top:34px;
}
#bd7395f4{
  text-align:left;
  color:#141620;
  font-size:30px;
  font-family:PingFang SC;
}
#e72b3603{
  width:291px;
  position:relative;
  margin-left:155px;
  display:inline-block;
  vertical-align: middle;
}
#a40c4e00{
}
#a40c4e00{
  text-align:left;
  color:#141620;
  font-size:45px;
  font-family:PingFang SC;
}
#ab904f3e{
  width:291px;
  height:291px;
  margin-top:20px;
}
#ab904f3e{
  vertical-align:middle;
}
#a0839ce0{
  margin-top:63px;
}
#a0839ce0{
  text-align:right;
  color:#6f7484;
  font-size:60px;
  font-family:PingFang SC;
}
#a3c8b63d{
  width:680px;
  margin-top:130px;
  margin-left:321px;
  margin-bottom:50px;
}
#a3c8b63d{
  background-color:rgb(20,22,32,1);
  border-radius:6px 6px 6px 6px;
}
#a3c8b63d{
  color:#fff;
  font-size:30px;
  font-family:PingFang SC;
  border:transparent;
}
#f00af516{
  width:180px;
  position:relative;
  margin-top:160px;
  padding-left:734px;
}
#aac6cbab{
}
#aac6cbab{
  text-align:center;
  color:#1c1c1c;
  font-size:45px;
  font-family:PingFang SC;
}
#fae93849{
  width:120px;
  height:5px;
  margin-top:10px;
  padding-left:30px;
}
#fae93849{
  vertical-align:middle;
}
#aa1cc42d{
  width:1328px;
  position:relative;
  margin-top:60px;
  padding-left:160px;
}
#a176989f{
  width:634px;
  height:383px;
  display:inline-block;
  vertical-align: middle;
}
#a176989f{
  cursor: pointer;
}
#af55dd6b{
  width:634px;
  height:383px;
  margin-left:60px;
  display:inline-block;
  vertical-align: middle;
}
#af55dd6b{
  cursor: pointer;
}
#aed91e13{
  width:1328px;
  position:relative;
  margin-top:60px;
  padding-left:160px;
  padding-bottom:370px;
}
#a35d495b{
  width:634px;
  height:383px;
  display:inline-block;
  vertical-align: middle;
}
#a35d495b{
  cursor: pointer;
}
#a9878a12{
  width:634px;
  height:383px;
  margin-left:60px;
  display:inline-block;
  vertical-align: middle;
}
#a9878a12{
  cursor: pointer;
}
#a31e5a3c{
  width:150px;
  position:fixed;
  z-index: 50;
  top:918px;
  left:1740px;
}
#a31e5a3c{
  background-color:rgb(20,22,32,1);
  border-radius:5px 5px 5px 5px;
}
#dlgfaafacbdb{
  position:fixed;
  z-index: 100;
  top:0;
  right:0;
  bottom:0;
  left:0;
  background:rgba(0,0,0,0.5);
}
#aafacbdb{
  position:absolute;
  top:645px;
  left:610px;
  width:700px;
  height:312px;
  border-radius:4px 4px 4px 4px;
  background:#fff;
}
#aa56df09{
  padding-top:50px;
}
#aa56df09{
  text-align:center;
  color:#141620;
  font-size:27px;
  font-family:PingFang SC;
}
#a77268ee{
  width:500px;
  margin-top:40px;
  margin-left:100px;
  margin-bottom:50px;
}
#a77268ee{
  background-color:rgb(20,22,32,1);
  border-radius:6px 6px 6px 6px;
}
#a77268ee{
  color:#fff;
  font-size:30px;
  font-family:PingFang SC;
  border:transparent;
}
/*在此注释下方添加自定义样式*/
</style>
